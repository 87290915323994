import React from "react";
import { NavLink } from "react-router-dom";

const ShoppingCartDropdown = ({ cart, loading, cartDropdownRef, handleRemoveFromCart }) => {
    if (loading) {
        return (
            <div className="absolute right-[-21px] top-[2rem] mt-2 w-80 border-2 border-black bg-white shadow-xl rounded-lg p-4 z-50 flex items-center justify-center h-32">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                    width="100"
                    height="100"
                    style={{
                        shapeRendering: 'auto',
                        display: 'block',
                    }}
                >
                    <rect fill="#0099e5" height="12" width="6" ry="6" rx="3" y="24" x="47">
                        <animate repeatCount="indefinite" begin="-0.9166666666666666s" dur="1s" keyTimes="0;1" values="1;0" attributeName="opacity" />
                    </rect>
                    <g transform="rotate(30 50 50)">
                        <rect fill="#0099e5" height="12" width="6" ry="6" rx="3" y="24" x="47">
                            <animate repeatCount="indefinite" begin="-0.8333333333333334s" dur="1s" keyTimes="0;1" values="1;0" attributeName="opacity" />
                        </rect>
                    </g><g transform="rotate(60 50 50)">
                        <rect fill="#0099e5" height="12" width="6" ry="6" rx="3" y="24" x="47">
                            <animate repeatCount="indefinite" begin="-0.75s" dur="1s" keyTimes="0;1" values="1;0" attributeName="opacity" />
                        </rect>
                    </g><g transform="rotate(90 50 50)">
                        <rect fill="#0099e5" height="12" width="6" ry="6" rx="3" y="24" x="47">
                            <animate repeatCount="indefinite" begin="-0.6666666666666666s" dur="1s" keyTimes="0;1" values="1;0" attributeName="opacity" />
                        </rect>
                    </g><g transform="rotate(120 50 50)">
                        <rect fill="#0099e5" height="12" width="6" ry="6" rx="3" y="24" x="47">
                            <animate repeatCount="indefinite" begin="-0.5833333333333334s" dur="1s" keyTimes="0;1" values="1;0" attributeName="opacity" />
                        </rect>
                    </g><g transform="rotate(150 50 50)">
                        <rect fill="#0099e5" height="12" width="6" ry="6" rx="3" y="24" x="47">
                            <animate repeatCount="indefinite" begin="-0.5s" dur="1s" keyTimes="0;1" values="1;0" attributeName="opacity" />
                        </rect>
                    </g><g transform="rotate(180 50 50)">
                        <rect fill="#0099e5" height="12" width="6" ry="6" rx="3" y="24" x="47">
                            <animate repeatCount="indefinite" begin="-0.4166666666666667s" dur="1s" keyTimes="0;1" values="1;0" attributeName="opacity" />
                        </rect>
                    </g><g transform="rotate(210 50 50)">
                        <rect fill="#0099e5" height="12" width="6" ry="6" rx="3" y="24" x="47">
                            <animate repeatCount="indefinite" begin="-0.3333333333333333s" dur="1s" keyTimes="0;1" values="1;0" attributeName="opacity" />
                        </rect>
                    </g><g transform="rotate(240 50 50)">
                        <rect fill="#0099e5" height="12" width="6" ry="6" rx="3" y="24" x="47">
                            <animate repeatCount="indefinite" begin="-0.25s" dur="1s" keyTimes="0;1" values="1;0" attributeName="opacity" />
                        </rect>
                    </g><g transform="rotate(270 50 50)">
                        <rect fill="#0099e5" height="12" width="6" ry="6" rx="3" y="24" x="47">
                            <animate repeatCount="indefinite" begin="-0.16666666666666666s" dur="1s" keyTimes="0;1" values="1;0" attributeName="opacity" />
                        </rect>
                    </g><g transform="rotate(300 50 50)">
                        <rect fill="#0099e5" height="12" width="6" ry="6" rx="3" y="24" x="47">
                            <animate repeatCount="indefinite" begin="-0.08333333333333333s" dur="1s" keyTimes="0;1" values="1;0" attributeName="opacity" />
                        </rect>
                    </g><g transform="rotate(330 50 50)">
                        <rect fill="#0099e5" height="12" width="6" ry="6" rx="3" y="24" x="47">
                            <animate repeatCount="indefinite" begin="0s" dur="1s" keyTimes="0;1" values="1;0" attributeName="opacity" />
                        </rect>
                    </g></svg>
            </div >
        );
    }

    // Handle empty cart state
    if (!cart || cart.items.length === 0) {
        return (
            <div className="absolute right-[-21px] top-[2rem] mt-2 w-64 border-2 border-black bg-white shadow-xl rounded-lg p-4 z-50 flex items-center justify-center h-32">
                Your cart is empty.
            </div>
        );
    }

    return (
        <div
            ref={cartDropdownRef}
            className="absolute right-[-21px] top-[2rem] mt-2 w-72 border-2 border-black bg-white shadow-xl rounded-lg p-4 z-50"
        >
            <div
                className="absolute top-[-8px] right-[55px] transform -translate-x-1/2 w-0 h-0 border-l-8 border-r-8 border-b-8 border-transparent border-b-black"
            />

            {/* Cart Items */}
            <div className={`space-y-4 ${cart.items.length > 3 ? 'overflow-y-auto max-h-48' : ''}`}>
                {cart.items.map((item) => (
                    <div key={item.id} className="flex items-center justify-between">
                        <div className="flex items-center space-x-3">
                            <img
                                src={item.design.preview_image}
                                alt={item.design.title}
                                className="w-12 h-12 rounded object-cover"
                            />
                            <div>
                                <p className="font-semibold">{item.design.title}</p>
                                <p className="text-sm text-gray-500">${item.price.toFixed(2)}</p>
                            </div>
                        </div>
                        <button
                            onClick={() => handleRemoveFromCart(item.design.id)}
                            className="text-red-500 hover:text-red-700 transition duration-200 mr-3"
                        >
                            ✕
                        </button>
                    </div>
                ))}
            </div>

            <hr className="my-4" />

            {/* Total Price */}
            <div className="flex justify-between items-center mb-[15px]">
                <div className="font-semibold text-left">Total:</div>
                <div className="font-semibold text-right">
                    {cart.total_price_before !== cart.total_price_after ? (
                        <>
                            <span className="line-through text-gray-500 mr-2">
                                ${cart.total_price_before.toFixed(2)}
                            </span>
                            <span className="text-black">
                                ${cart.total_price_after.toFixed(2)}
                            </span>
                        </>
                    ) : (
                        <span className="text-black">${cart.total_price_after.toFixed(2)}</span>
                    )}
                </div>
            </div>

            {/* View Bag Button */}
            <NavLink to="/ShoppingCart"
                className="bg-black text-white px-[4rem] py-[6px] mx-auto flex items-center justify-center rounded-lg hover:bg-gray-800 transition duration-200 ease-in-out">
                View Bag
            </NavLink>
        </div>
    );
};

export default ShoppingCartDropdown;
