import React from "react";
import useCart from "../../Hooks/cart/useCart";
import CartContext from "./CartContext";

const CartProvider = ({ children }) => {
  const cartState = useCart();
  
  return (
    <CartContext.Provider value={cartState}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
