import React, { createContext, useContext, useState } from "react";
import { registerUser, loginUser, logoutUser } from "../Services/authApi/authService";
import { verifyCode, resendVerificationCode } from "../Services/authApi/verificationService";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [authError, setAuthError] = useState(null);
    const [verificationRequired, setVerificationRequired] = useState(false);

    const handleRegister = async (formData) => {
        try {
            const response = await registerUser(formData);
            setAuthError(null);
            return response.message;
        } catch (error) {
            setAuthError(error.message || "Registration failed");
            throw error;
        }
    };

    const handleLogin = async (credentials) => {
        try {
            const response = await loginUser(credentials);
            setAuthError(null);
            return response.message;
        } catch (error) {
            setAuthError(error.message || "Login failed");
            throw error;
        }
    };

    const handleLogout = async () => {
        try {
            await logoutUser();
            setUser(null);
        } catch (error) {
            setAuthError(error.message || "Logout failed");
            throw error;
        }
    };

    const handleVerify = async (verificationCode) => {
        try {
            const response = await verifyCode(verificationCode);
            setAuthError(null);
            setVerificationRequired(false);
            setUser(response.user);
            return response.message;
        } catch (error) {
            setAuthError(error.message || "Verification failed");
            throw error;
        }
    };

    const handleResendCode = async () => {
        try {
            const response = await resendVerificationCode();
            setAuthError(null);
            return response.message;
        } catch (error) {
            setAuthError(error.message || "Failed to resend verification code");
            throw error;
        }
    };


    return (
        <AuthContext.Provider value={{
            user, handleRegister, handleLogin, handleLogout, handleVerify,
            handleResendCode,
            authError,
            verificationRequired,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
