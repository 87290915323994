import { useState } from "react";
import { getGoogleLoginUrl } from "../Services/authApi/authService";

const useGoogleLogin = () => {
  const [loading, setLoading] = useState(false);

  const loginWithGoogle = async () => {
    setLoading(true);
    try {
      const { data } = await getGoogleLoginUrl();
      if (data?.url) {
        window.location.href = data.url;
      } else {
        throw new Error("Invalid login URL received");
      }
    } catch (error) {
      console.error("Google login failed:", error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loginWithGoogle, loading };
};

export default useGoogleLogin;