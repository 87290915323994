import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToasterAuth = ({ message, type }) => {
  useEffect(() => {
    if (message) {
      type === "success" ? toast.success(message) : toast.error(message);
    }
  }, [message, type]);

  return <ToastContainer position="top-center" autoClose={3000} />;
};

export default ToasterAuth;
