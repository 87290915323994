import axios from "axios";

const API_URL = "https://api.opal-dp.com";

export const registerUser = async (formData) => {
  try {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;

    await axios.get(`${API_URL}/sanctum/csrf-cookie`);
    const response = await axios.post(`${API_URL}/v1/auth/register`, formData);
    const { token, user, verify } = response.data.data;

    // Save token, name, and email in local storage
    localStorage.setItem('authToken', token);
    localStorage.setItem('userName', user.name);
    localStorage.setItem('userEmail', user.email);
    localStorage.setItem('verify', verify);

    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : { message: "An error occurred" };
  }
};

export const loginUser = async (credentials) => {
  try {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;

    await axios.get(`${API_URL}/sanctum/csrf-cookie`);
    const response = await axios.post(`${API_URL}/v1/auth/login`, credentials);
    const { token, user, verify } = response.data.data;

    // Save token, name, and email in local storage
    localStorage.setItem('authToken', token);
    localStorage.setItem('userName', user.name);
    localStorage.setItem('userEmail', user.email);
    localStorage.setItem('verify', verify);
    console.log(localStorage.getItem("authToken"))

    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : { message: 'An error occurred' };
  }
};


export const logoutUser = async () => {
  try {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;

    const token = localStorage.getItem('authToken');
    const response = await axios.post(
      `${API_URL}/v1/auth/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Remove all auth-related items from local storage
    localStorage.removeItem('authToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('verify');

    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Logout failed");
  }
};

export const getGoogleLoginUrl = async () => {
  try {
    const response = await axios.get(`${API_URL}/v1/auth/login/google`);
    return response.data;
  } catch (error) {
    console.error("Error fetching Google login URL:", error);
    throw new Error(error.response?.data?.message || "Failed to get login URL");
  }
};
