import React from "react";
import MobileFooter from "./MobileFooter";
import DesktopFooter from "./DesktopFooter";

import Footerbg from '../../../Assets/Images/Footer.webp';

const Footer = () => {
    return (
        <div
            className="slick bottom-0 pt-16 mx-0 rounded-tl-lg rounded-tr-lg md:px-24 lg:px-[2rem] bg-cover bg-no-repeat bg-center"
            style={{
                backgroundImage: `url(${Footerbg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
        >
            {/* Mobile Design */}
            <MobileFooter />
            {/* Desktop Design */}
            <DesktopFooter />
            <div className="flex flex-col-reverse items-center justify-center border-t border-black lg:flex-row p-4">
                <p className="text-[12px] text-gray-600 text-center xl:text-center lg:text-center md:text-center">
                    © Copyright 2025 K&K Group Developers Inc. All rights reserved.
                </p>
            </div>
        </div>
    );
};

export default Footer;
