import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const HomePage = lazy(() => import('../Pages/HomePage'));
const TrendPage = lazy(() => import('../Pages/Trend'));
const AboutPage = lazy(() => import('../Pages/AboutUs'));
const Printer = lazy(() => import('../Pages/Printer'));
const DesignsPage = lazy(() => import('../Pages/DesignPage'));
const ProductDetail = lazy(() => import('../Pages/ProductDetail'));
const PersonalInfo = lazy(() => import('../Pages/PersonInfo'));
const ShoppingCart = lazy(() => import('../Pages/ShoppingCart'));
const Pricing = lazy(() => import('../Pages/Pricing'));

const RoutesConfig = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/trends" element={<TrendPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/printer" element={<Printer />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/studio/floral" element={<DesignsPage />} />
        <Route path="/details/:id" element={<ProductDetail />} />
        <Route path="/PersonalInfo" element={<PersonalInfo />} />
        <Route path="/ShoppingCart" element={<ShoppingCart />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesConfig;