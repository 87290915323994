import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import Search from "./Search";
import ShoppingCart from "./ShoppingCartDropdown";
import ProfileCart from "./ProfileCart";
import AuthModal from "../../Auth/AuthModal";
import CartContext from "../../../contexts/cart/CartContext";

const SecondaryNav = () => {
    const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [isProfileCartOpen, setIsProfileCartOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { cart, handleRemoveFromCart, loading } = useContext(CartContext);

    const closeModal = () => setIsModalOpen(false);

    const megaMenuRef = useRef(null);
    const buttonRef = useRef(null);
    const cartButtonRef = useRef(null);
    const cartDropdownRef = useRef(null);
    const profileCartRef = useRef(null);
    const profileButtonRef = useRef(null);

    const toggleMegaMenu = () => setIsMegaMenuOpen(!isMegaMenuOpen);
    const toggleCartDropdown = () => setIsCartOpen(!isCartOpen);

    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    // Clear the invalid token and related auth info
                    localStorage.removeItem("authToken");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("userEmail");
                    localStorage.removeItem("verify");
                    // Close any open profile cart
                    setIsProfileCartOpen(false);
                    // Open the authentication modal
                    setIsModalOpen(true);
                }
                return Promise.reject(error);
            }
        );

        // Cleanup: remove the interceptor when the component unmounts
        return () => axios.interceptors.response.eject(interceptor);
    }, []);

    // Close dropdowns when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Close Mega Menu
            if (
                isMegaMenuOpen &&
                megaMenuRef.current &&
                !megaMenuRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsMegaMenuOpen(false);
            }
            // Close Cart Dropdown
            if (
                isCartOpen &&
                cartDropdownRef.current &&
                !cartDropdownRef.current.contains(event.target) &&
                cartButtonRef.current &&
                !cartButtonRef.current.contains(event.target)
            ) {
                setIsCartOpen(false);
            }
            // Close Profile Cart
            if (
                isProfileCartOpen &&
                profileCartRef.current &&
                !profileCartRef.current.contains(event.target) &&
                profileButtonRef.current &&
                !profileButtonRef.current.contains(event.target)
            ) {
                setIsProfileCartOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, [isMegaMenuOpen, isCartOpen, isProfileCartOpen]);

    return (
        <div className="w-full relative z-30">
            <div className="flex items-end justify-end space-x-3">
                <button ref={buttonRef} className="flex hover:text-[#bc0033]" onClick={toggleMegaMenu}>
                    <svg className="h-6 w-6 2xl:h-8 2xl:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </button>

                <button ref={cartButtonRef} className="flex items-center hover:text-[#bc0033] relative" onClick={toggleCartDropdown}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 2xl:h-8 2xl:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                    {cart?.items?.length > 0 && (
                        <svg className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2" width="16" height="16">
                            <circle cx="8" cy="8" r="8" fill="red" />

                            <text x="8" y="11" textAnchor="middle" fontSize="10" fill="white" fontWeight="bold">
                                {cart.items.length}
                            </text>
                        </svg>
                    )}
                </button>

                <button
                    className="flex items-center group"
                    ref={profileButtonRef}
                    onClick={() => {
                        const token = localStorage.getItem("authToken");
                        if (token) {
                            setIsProfileCartOpen(prev => !prev);
                        } else {
                            setIsModalOpen(true);
                        }
                    }}
                >
                    <svg
                        className="h-7 w-7 2xl:h-9 2xl:w-9"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            className="group-hover:stroke-[#000000]"
                            opacity="0.4"
                            d="M12.1207 12.78C12.0507 12.77 11.9607 12.77 11.8807 12.78C10.1207 12.72 8.7207 11.28 8.7207 9.50998C8.7207 7.69998 10.1807 6.22998 12.0007 6.22998C13.8107 6.22998 15.2807 7.69998 15.2807 9.50998C15.2707 11.28 13.8807 12.72 12.1207 12.78Z"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            className="group-hover:stroke-[#0d0505]"
                            opacity="0.34"
                            d="M18.7398 19.3801C16.9598 21.0101 14.5998 22.0001 11.9998 22.0001C9.39977 22.0001 7.03977 21.0101 5.25977 19.3801C5.35977 18.4401 5.95977 17.5201 7.02977 16.8001C9.76977 14.9801 14.2498 14.9801 16.9698 16.8001C18.0398 17.5201 18.6398 18.4401 18.7398 19.3801Z"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            className="group-hover:stroke-[#bc0033]"
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            </div>

            <div
                ref={megaMenuRef}
                className={`absolute top-[40px] right-[-72px] xl:right-[-32px] lg:right-[-32px] mt-2 
                w-[100vw] max-w-[1529px] md:max-w-[768px] lg:max-w-[1513px] 2xl:max-w-[1875px] h-[300px] 
                bg-gray-100 shadow-lg p-4 z-50 flex justify-center 
                transition-all duration-300 ease-in-out transform ${isMegaMenuOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-5 pointer-events-none'
                    }`}
            >
                <Search toggleMegaMenu={toggleMegaMenu} />
            </div>

            {isCartOpen && (
                <ShoppingCart cart={cart} loading={loading} cartDropdownRef={cartDropdownRef} handleRemoveFromCart={handleRemoveFromCart} />
            )}

            {isProfileCartOpen &&
                <div ref={profileCartRef}>
                    <ProfileCart
                        profileDropdownRef={profileCartRef}
                        setIsProfileCartOpen={setIsProfileCartOpen}
                    />
                </div>
            }

            {/* Auth Modal */}
            <AuthModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
    );
};

export default SecondaryNav;
