import axios from "axios";

const API_URL = "https://api.opal-dp.com";
const token = localStorage.getItem("authToken")

export const fetchCart = async () => {
    try {
        const response = await axios.get(`${API_URL}/v1/api/cart`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching cart:", error);
        return null;
    }
};

export const addToCart = async (designId, quantityItem) => {
    try {
        const response = await axios.post(`${API_URL}/v1/api/cart/add`,
            { design_id: designId, quantity: quantityItem },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error adding item to cart:", error);
        throw error;
    }
};

export const removeFromCart = async (designId) => {
    try {
        const response = await axios.delete(`${API_URL}/v1/api/cart/remove`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: { design_id: designId },
        });
        return response.data;
    } catch (error) {
        console.error("Error removing item from cart:", error);
        throw error;
    }
};

