import { useState, useEffect } from "react";
import { fetchCart, addToCart, removeFromCart } from "../../Services/cart/cartService";

const useCart = () => {
  const [cart, setCart] = useState({ items: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch cart data
  const getCart = async () => {
    setLoading(true);
    try {
      const cartData = await fetchCart();
      if (cartData && cartData.status) {
        setCart(cartData.data);
      }
    } catch (err) {
      setError("Failed to fetch cart.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch cart data on component mount
  useEffect(() => {
    getCart();
  }, []);

  // Function to handle adding an item to the cart
  const handleAddToCart = async (designId, quantityItem) => {
    setLoading(true);
    setError(null);
    try {
      const response = await addToCart(designId, quantityItem);
      if (response.status) {
        // Refetch the cart after adding an item
        await getCart();
      }
    } catch (err) {
      setError("Failed to add item to cart.");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveFromCart = async (designId) => {
    console.log(designId)
    setLoading(true);
    setError(null);
    try {
        const response = await removeFromCart(designId);
        if (response.status) {
            await getCart();
        }
    } catch (err) {
        setError("Failed to remove item from cart.");
    } finally {
        setLoading(false);
    }
};

  return { cart, handleAddToCart, handleRemoveFromCart, loading, error };
};

export default useCart;
